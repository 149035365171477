angular.module('MyHippoProducer.Components').directive('transactionBreakdown', function () {
    return {
        restrict: 'E',
        scope: { simpleBreakdown: '<' },
        templateUrl: 'components/transaction-breakdown/transaction-breakdown.html',
        controller: function ($scope, PolicyService, WorkflowAdapter) {
            if (PolicyService.hasActiveTransaction) {
                PolicyService.fetchTransactionBreakdown(PolicyService.id).then((breakdown) => {
                    $scope.transactionLastModified = moment(PolicyService.transactionLastModified).format('MM/DD/YYYY');
                    $scope.breakdown = breakdown.filter((change) => {
                        return change.page_key !== 'debug_page';
                    }).map((change) => {
                        const field = WorkflowAdapter.getField(change.field_key);
                        if (field && change.new_value !== undefined) {
                            change.format = field.format || field.type;
                            if (field.options) {
                                change.format = `dropdown:${change.format}`;
                                change.options = field.options;
                            }
                        }
                        return change;
                    });
                });
            }
        },
    };
});
